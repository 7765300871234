<template>
  <div class="menu">
    <el-menu
      active-text-color="#ffd04b"
      background-color="#112f50"
      class="el-menu-vertical-demo"
      :default-active="route.path"
      text-color="#fff"
      :collapse="isClose"
      router
    >
      <el-menu-item>
        <template #title>
          <el-icon>
            <House />
          </el-icon>
          <span class="title" @click="goHome">管理系统V0.1</span>
        </template>
      </el-menu-item>
        <!-- <el-menu-item index="/orders/orders_create">创建订单</el-menu-item> -->
      <el-menu-item index="/">
        <el-icon><HomeFilled /></el-icon>
        首页
      </el-menu-item>
      <template v-for="item in menuItems" :key="item.id">
        <el-sub-menu v-if="item.subResources && item.subResources.length" :index="item.route">
          <template #title>
            <el-icon>
              <component :is="getIconComponent(item.icon)"></component>
            </el-icon>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item v-for="subItem in item.subResources" :key="subItem.id" :index="subItem.route">
            {{ subItem.name }}
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item v-else :index="item.route">
          <el-icon>
            <component :is="getIconComponent(item.icon)"></component>
          </el-icon>
          <span>{{ item.name }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { Setting, Tickets, UserFilled, TrendCharts, ShoppingBag, Document,Coin,Management,Stamp } from '@element-plus/icons-vue';

// 定义 props
defineProps({
  isClose: Boolean,
  menuItems: Array // 确保 menuItems 被正确声明
});

const route = useRoute();
const goHome = ()=>{
  router.replace('/');
}
// 定义获取图标组件的方法
const getIconComponent = (icon) => {
  switch (icon) {
    case 'setting':
      return Setting;
    case 'tickets':
      return Tickets;
    case 'user-filled':
      return UserFilled;
    case 'trend-charts':
      return TrendCharts;
    case 'shopping-bag':
      return ShoppingBag;
    case 'document':
      return Document;
    case 'coin':
      return Coin;
    case 'management':
      return Management;
    case 'stamp':
      return Stamp;
    default:
      return Document;
  }
};

</script>

<style lang="less" scoped>
.menu {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.title {
  font-size: 18px;
  color: cornsilk;
}
.el-menu {
  border-right: none;
}
</style>