// src/http.js
import axios from 'axios';
import { createPinia, setActivePinia } from 'pinia';
import router from './router';
import { ElMessage } from 'element-plus';

// 创建并激活 Pinia 实例
const pinia = createPinia();
setActivePinia(pinia);

axios.defaults.baseURL = 'https://service.cdmatrix.cn';

// 请求拦截
axios.interceptors.request.use(
  config => {
    const authStore = require('@/store/authStore').useAuthStore();
    if (authStore.token) {
      config.headers.Authorization = 'Bearer ' + authStore.token;
    }
    return config;
  },
  error => {
    console.log('请求拦截器：错误');
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  response => {
    switch (response.data.state) {
      case 401:
        router.push('/login');
        break;
      case 403:
        console.log('响应拦截器：权限不足');
        break;
      case 404:
        console.log('响应拦截器：找不到资源');
        break;
      case 500:
        console.log('响应拦截器：服务器错误');
        break;
      case 200:
        console.log('响应拦截器：请求成功');
        break;
    }
    return response;
  },
  error => {
    console.log('响应拦截器: 错误', error.response);
    const authStore = require('@/store/authStore').useAuthStore();
    if (error.response) {
      let errorMessage = '';
      switch (error.response.status) {
        case 400:
          errorMessage = error.response.data.msg;
          break;
        case 401:
          authStore.logout();
          router.push('/login');
          break;
        case 403:
          errorMessage = '您没有权限访问当前页面';
          break;
        case 404:
          errorMessage = '请求的内容不存在';
          break;
        case 500:
          errorMessage = error.response.data.msg;
          break;
      }
      ElMessage({
        message: errorMessage,
        type: 'warning',
      });
    } else {
      authStore.logout();
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default axios;