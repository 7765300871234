import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'normalize.css';
import '@/assets/css/base.css';
import router from './router/index';
import '@/router/permission';
import pinia from './store'; // 引入 Pinia 实例
import '@/http.js'; // 确保 axios 配置加载

const app = createApp(App);

// 注册所有 Element Plus Icons
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 使用 Element Plus
app.use(ElementPlus);

// 使用全局消息组件
import { ElMessage } from 'element-plus';
app.config.globalProperties.$message = ElMessage;

// 使用打印插件
import print from 'vue3-print-nb';
app.use(print);

// 使用 Pinia
app.use(pinia);

// 使用路由
app.use(router);

// 获取用户权限列表
import { useAuthStore } from '@/store/authStore'; // 确保正确导入 useAuthStore
const authStore = useAuthStore();

if (authStore.token) {
  authStore.fetchUserPermissions().then(() => {
    app.mount('#app');
  }).catch(error => {
    //console.error('Failed to fetch user permissions:', error);
    app.mount('#app');
  });
} else {
  app.mount('#app');
}

