import { defineStore } from 'pinia';
import api from '@/api/api';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('token') || '',
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
    refreshToken: localStorage.getItem('refreshToken') || '',
    menuItems: localStorage.getItem('menuItem') || [] // 新增 menuItems 属性
  }),
  actions: {
    login(user) {
      this.token = user.accessToken;
      this.userInfo = user.user;
      this.refreshToken = user.refreshToken;

      // 持久化存储
      localStorage.setItem('token', this.token);
      localStorage.setItem('refreshToken', this.refreshToken);
      localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
    },
    logout() {
      // 清除持久化数据
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('refreshToken');

      this.token = '';
      this.userInfo = null;
      this.menuItems = [];
    },
    isLoggedIn() {
      return !!this.token;
    },
    setMenuItems(menuItems) {
      this.menuItems = menuItems;
      //console.log('Menu items set:', menuItems); // 添加调试信息
    },
    async fetchUserPermissions() {
      try {
        const response = await api.fetchUserPermissions();
        const { data } = response; // 提取 data 对象

        //console.log('Fetched permissions:', data); // 添加调试信息
        //console.log('Type of data:', typeof data); // 添加调试信息

        if (!Array.isArray(data.data)) {
          //console.error('Permissions data is not an array:', data.data); // 添加调试信息
          throw new Error('Permissions data is not an array');
        }

        // 处理嵌套的权限数据
        const menuItems = this.processPermissions(data.data);

        this.setMenuItems(menuItems);
        localStorage.setItem('menuItem', JSON.stringify(this.menuItems));

        //console.log('User permissions:', menuItems); // 添加调试信息
      } catch (error) {
        //console.error('Failed to fetch user permissions:', error);
        throw error;
      }
    },
    processPermissions(permissions) {
      return permissions.map(permission => ({
        id: permission.id,
        name: permission.name,
        route: permission.route,
        icon:permission.icon,
        subResources: this.processSubResources(permission.subResources)
      }));
    },
    processSubResources(subResources) {
      if (!Array.isArray(subResources)) {
        return []; // 返回空数组
      }

      return subResources.map(subResource => ({
        id: subResource.id,
        name: subResource.name,
        route: subResource.route
      }));
    }
  },
  getters: {
    getMenuItems: (state) => state.menuItems
  }
});