import axios from '@/http';
import base from './base';

const api = {
  // 用户登录
  userLogin(params) {
    return axios.post(base.login.webapi_login, params);
  },
  // 获得产品列表
  getProducts(params) {
    return axios.get(base.product.webapi_products, { params });
  },
  // 添加产品
  addProducts(params) {
    return axios.post(base.product.webapi_products, params);
  },
  // 导出模板
  exportProducts() {
    return process.env.VUE_APP_BASE_API + base.product.webapi_products_export;
  },
  // 删除商品
  deleteProducts(params) {
    return axios.delete(base.product.webapi_products + '/' + params);
  },
  // 创建订单
  createOrders(params) {
    return axios.post(base.orders.webapi_createOrders, params);
  },
  // 管理员给指定客户创建订单
  createOrdersAdmin(params) {
    return axios.post(base.orders.webapi_createOrdersAdmin, params);
  },
  // 获得订单
  getOrders(params) {
    return axios.get(base.orders.webapi_orders, { params });
  },
  // 获得订单详情
  getOrdersDetail(params) {
    return axios.get(base.orders.webapi_orders + '/' + params);
  },
  // 订单统计
  getOrdersOrderStatistics(params) {
    return axios.get(base.orders.webapi_orders_ordersStatistics, { params });
  },
  // 获得订单产品统计
  getOrdersStatistics(params) {
    return axios.get(base.orders.webapi_orders_statistics, { params });
  },
  // 获得订单统计
  getExportStatistics(params) {
    return axios.get(base.orders.webapi_orders_export_statistics, {
      responseType: 'blob',
      params: params,
    });
  },
  // 订单汇总报表金额
  getSummaryStatistics(params) {
    return axios.get(base.orders.webapi_orders_summarystatistics, { params });
  },
  putOrderProduct(orderId, params) {
    return axios.put(base.orders.webapi_orders_modifyproducts + '/' + orderId, params);
  },
  // 获得公司列表
  getCompanies(params) {
    return axios.get(base.company.webapi_company, { params });
  },
  // 得到公司下的用户
  getCompaniesUser() {
    return axios.get(base.company.webapi_company_member);
  },
  // 添加公司管理员
  addCompaniesAdminUser(params) {
    return axios.post(base.company.user.webapi_company_user, params);
  },
  // 添加公司
  addCompanise(params) {
    return axios.post(base.company.webapi_company, params);
  },
  // 删除公司
  deleteCompanise(params) {
    return axios.delete(base.company.webapi_company + '/' + params);
  },
  // 修改订单商品采购单价
  modifyPurchasePrice(params) {
    return axios.put(base.orders.webapi_orders_purchase_price, params);
  },
  // 修改订单商品售价
  modifySupply_price(params) {
    return axios.put(base.orders.webapi_orders_supply_price, params);
  },
  // 采购计价完成
  postOrderPurchased(params) {
    return axios.post(base.orders.webapi_orders_purchased + '/' + params);
  },
  // 管理计划完成
  postOrderPriced(params) {
    return axios.post(base.orders.webapi_orders_priced + '/' + params);
  },
  // 客户付款完成
  postOrderPayment(params) {
    return axios.post(base.orders.webapi_orders_payment + '/' + params);
  },
  // 财务结算完成
  postOrderSettled(params) {
    return axios.post(base.orders.webapi_orders_settled + '/' + params);
  },
  fetchUserPermissions() {
    return axios.get(base.login.webapi_resources);
    // return axios.get(base.login.webapi_resources).then(response => {
    //   return response;
    // }).catch(error => {
    //   console.error('API request failed:', error);
    //   //throw error;
    // });
  }
};

export default api;