<template>
  <div class="layout-menu">
    <LayoutMenu :isClose="isClose" :menuItems="menuItems"></LayoutMenu>
  </div>
  <div class="layout-content" :class="{active:isClose}">
    <LayoutContent :isClose="isClose" @change="change"></LayoutContent>
  </div>
</template>

<script>
import LayoutMenu from './LayoutMenu';
import LayoutContent from './LayoutContent';
import { ref } from 'vue';
import { useAuthStore } from '@/store/authStore';

export default {
  components: {
    LayoutMenu,
    LayoutContent
  },
  setup() {
    const isClose = ref(false);
    const authStore = useAuthStore();
    const menuItems = authStore.getMenuItems;

    function change() {
      isClose.value = !isClose.value;
    }

    return { isClose, change, menuItems };
  }
};
</script>

<style lang="less" scoped>
.layout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #112f50;
}
.layout-content {
  padding-left: 179px;
}
.active {
  padding-left: 64px;
}
</style>