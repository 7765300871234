<template>
    <div class='header'>
        <div class="icon" @click="change">
            <el-icon v-if="isClose">
                <Expand />
            </el-icon>
            <el-icon v-else>
                <Fold />
            </el-icon>
        </div>
        <div class="right">
            当前用户:{{ authStore.userInfo.company.companyName }}&nbsp;&nbsp;&nbsp;&nbsp;<span
                @click="dialogForm = true">修改密码</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="logout">注销</span>
        </div>
    </div>
    <div class="wrapper">
        <router-view />
    </div>

    <el-dialog v-model="dialogForm" title="修改密码" width="500">
        <el-form :model="form">
            <el-form-item label="新密码" :label-width="100">
                <el-input v-model="form.pwd" autocomplete="off" type="password" />
            </el-form-item>
            <el-form-item label="验证新密码" :label-width="100">
                <el-input v-model="form.chkpwd" autocomplete="off" type="password" />
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogForm = false">关闭</el-button>
                <el-button type="primary" :disabled="btnDisabled" @click="modifyPwdHandle">
                    修改密码
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue';
import store from '@/store';
import router from '@/router/index';
import { useAuthStore } from '@/store/authStore';
import { modifyPwd } from '@/api/api_user';
import { ElMessage } from 'element-plus'

// 定义 props 和 emits
defineProps(['isCLose']);
defineEmits(['change']);

const authStore = useAuthStore();
const btnDisabled = ref(false);
//修改密码
const modifyPwdHandle = () => {
    if (form.chkpwd != form.pwd) {
        ElMessage({
            message: "两次密码不一致",
            type: 'error',
        })
    } else {
        let param = {
            Password: form.pwd
        }
        btnDisabled.value = true;
        modifyPwd(param).then(res => {
            if (res.status === 200) {
                if (res.data.success) {
                    ElMessage({
                        message: '密码修改完成。',
                        type: 'success',
                        duration: 1000
                    })
                    setTimeout(() => {
                        btnDisabled.value = false;
                        dialogForm.value = false;
                    }, 1000)
                }
            } else {
                ElMessage({
                    message: '请联系管理员',
                    type: 'error',
                    duration: 1000
                })
            }
        });
    }
}

// 切换菜单显示方法
const change = () => {
    emit('change');
};

// 注销方法
const logout = () => {
    authStore.logout();
    router.push('/login');
};

// 定义表单数据
const form = reactive({
    pwd: '',
    chkpwd: '',
});

// 控制对话框显示的状态
const dialogForm = ref(false);
</script>

<style lang="less" scoped>
.header {
    height: 35px;
    line-height: 35px;
    background-color: #1e78bf;
    color: white;

    .icon {
        width: 10%;
        float: left;
        padding-left: 20px;
        font-size: 24px;

        i {
            cursor: pointer;
        }
    }

    .right {
        width: 80%;
        float: left;
        padding-right: 20px;
        font-size: 16px;
        text-align: right;

        span {
            cursor: pointer;
        }
    }
}

.wrapper {
    padding: 5px;
}
</style>