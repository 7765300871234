import { createRouter,createWebHistory } from "vue-router"
import Layout from  '@/views/layout/LayoutIndex'
import Home from '@/views/home/HomeIndex'
import Login from '@/views/login/LoginIndex'  

//商品
const Goods =()=>import ('@/views/goods/GoodsIndex.vue')
const GoodsList = () =>import('@/views/goods/GoodsList.vue')
const GoodsAdd = ()=>import('@/views/goods/GoodsAdd.vue')
const GoodsImport = ()=>import('@/views/goods/GoodsImport.vue')
const GoodsSetPinyin = ()=>import('@/views/goods/GoodsSetPinyin.vue')
//用户
const User = ()=>import('@/views/user/UserIndex.vue')
const UserList = ()=>import('@/views/user/UserList.vue')
const UserAdd = ()=>import('@/views/user/UserAdd.vue')

//用户组
const UserGroupList = ()=>import('@/views/user/UserGroup.vue')

//客户
const Customer =()=>import('@/views/customers/CustomerIndex.vue')
const CustomerList =()=>import('@/views/customers/CustomerList.vue')
const CustomerAdd =()=>import('@/views/customers/CustomerAdd.vue')
const CustomerAddAdmin =()=>import('@/views/customers/CustomerAdminAdd.vue')
//配送单位
const ServiceCustomerAdd = () => import('@/views/customers/ServiceCustomerAdd.vue')
const ServiceCustomerList = () => import('@/views/customers/ServiceCustomerList.vue')
//进货公司
const RestockCustomerList = () => import('@/views/customers/RestockList.vue')
//订单
const Orders =()=>import('@/views/orders/OrdersIndex.vue')
const OrdersCreate =()=>import('@/views/orders/OrdersCreate.vue')
const OrdersCreateNew =()=>import('@/views/orders/OrdersCreateNew.vue')
const OrdersManager =()=>import('@/views/orders/OrdersManager.vue')
const OrdersStatistics =()=>import('@/views/orders/OrdersStatistics.vue')
const OrdersPurchaseStatistics =()=>import('@/views/orders/OrdersPurchaseStatistics.vue')
const OrdersSupplyStatistics =()=>import('@/views/orders/OrdersSupplyStatistics.vue')
//日志
const Log =()=>import('@/views/log/LogIndex.vue')
const LogOrders =()=>import('@/views/log/LogOrders.vue')
const LogPrices =()=>import('@/views/log/LogPrices.vue')
const LogSystem =()=>import('@/views/log/LogSystem.vue')

//统计
const FinanceIndex = () => import('@/views/finance/FinanceIndex.vue')
const FinanceGrossprofit = ()=>import('@/views/finance/FinanceGrossprofit.vue')

//管理员
const Admin =()=>import('@/views/admin/AdminIndex.vue')
const AdminPermissionDict =()=>import('@/views/admin/PermissionList.vue')
const AdminCompanyPermissions =()=>import('@/views/admin/CompanyPermissions.vue')
const AdminResourcesDict =()=>import('@/views/admin/ResourceList.vue')

//企业管理
const ManageMembers =()=>import('@/views/manage/ManageMembers.vue')
const ManageUserGroups =()=>import('@/views/manage/ManageUserGroups.vue')
const ManageRoles =()=>import('@/views/manage/ManageRoles.vue')


//路由配置
const routes = [
    {
        path: '/',
        component:Layout,
        children:[
            {
                path:'/' ,
                name:'home', 
                component:Home ,
                meta:{title:'矩元配送管理系统'},
            },
            {
                path:'/goods',
                component:Goods,
                children:[
                    {
                        path:'goods_list' ,
                        name:'goods_list',
                        component:GoodsList,
                        meta:{title:'品类'}
                    },
                    {
                        path:"goods_add" ,
                        name :'goods_add',
                        component:GoodsAdd,
                        meta:{title:'增加品类'}
                    },
                    {
                        path:"goods_import",
                        name:"goods_import",
                        component:GoodsImport,
                        meta:{title:'品类导出'}
                    },
                    {
                        path:"goods_pinyin",
                        name:"goods_pinyin",
                        component:GoodsSetPinyin,
                        meta:{title:'设置品类拼音'}
                    }
                ]
            },
            {
                path:'/user' ,
                component:User ,
                children:[
                    {
                        path:'list' ,
                        name:'list' ,
                        component:UserList ,
                        meta:{title:'用户列表'}
                    },
                    {
                        path:'add' ,
                        name:'add' ,
                        component:UserAdd,
                        meta:{title:'增加用户'}
                    },
                    {
                        path: 'user_group',
                        name: 'user_group',
                        component: UserGroupList,
                        meta: { title: "用户组管理" }
                    }
                ]
            },
            {
                path:'/customer' ,
                component:Customer,
                children:[
                    {
                        path:'customer_list' ,
                        component:CustomerList,
                        meta:{title:'单位列表'}
                    },
                    {
                        path:'customer_add' ,
                        component:CustomerAdd,
                        meta:{title:'增加单位'}
                    },
                    {
                        path:'customer_addadmin' ,
                        component:CustomerAddAdmin ,
                        meta:{title:'增加管理员用户'}
                    },
                    {
                        path: 'service_customer_add',
                        component: ServiceCustomerAdd,
                        meta: { title: '增加服务公司' }
                    },
                    {
                        path: 'service_customer_list',
                        component: ServiceCustomerList,
                        meta: { title: '服务公司列表' }
                    },
                    {
                        path:'rest_customer_list',
                        component:RestockCustomerList,
                        meta:{title:'进货公司管理'}
                    }
                ]
            },
            {
                path:'/orders',
                component:Orders ,
                children:[
                    {
                        path:'orders_create' ,
                        component:OrdersCreate
                    },
                    {
                        path:'orders_create_new' ,
                        component:OrdersCreateNew ,
                        meta:{title:'创建订单'}
                    },
                    {
                        path:'orders_manager' ,
                        component:OrdersManager,
                        meta:{title:'订单管理'}
                    },
                    {
                        path:'orders_statistics' ,
                        component:OrdersStatistics ,
                        meta:{title:'订单商品统计'}
                    },
                    {
                        path:'orders_supply_statistics' ,
                        component:OrdersSupplyStatistics,
                        meta:{title:'采购统计'}
                    },
                    {
                        path:'orders_purchase_statistics' ,
                        component:OrdersPurchaseStatistics,
                        meta:{title:'销售统计'}
                    }
                ]
            },
            {
                path:'/finance',
                component:FinanceIndex,
                children:[
                    {
                        path:'finance_grossprofit' ,
                        component:FinanceGrossprofit,
                        meta:{title:'毛利统计'}
                    }
                ]
            }
            ,
            {
                path:'/log',
                component:Log ,
                children:[
                    {
                        path:'log_orders' ,
                        component:LogOrders
                    },
                    {
                        path:'log_prices' ,
                        component:LogPrices
                    },
                    {
                        path:'log_system' ,
                        component:LogSystem
                    }
                ]
            },
            {
                path:'/admin',
                component:Admin ,
                children:[
                    {
                        path:'resources_dict' ,
                        component:AdminResourcesDict,
                        meta:{title:'菜单管理'},
                    },
                    {
                        path:'permission_dict' ,
                        component:AdminPermissionDict,
                        meta:{title:'权限字典'},
                    },
                    {
                        path:'company_permissions' ,
                        component:AdminCompanyPermissions,
                        meta:{title:'公司权限管理'},
                    }
                ]
            },
            {
                path:'/manage',
                component:Admin ,
                children:[
                    {
                        path:'members' ,
                        component:ManageMembers,
                        meta:{title:'单位成员'},
                    },
                    {
                        path:'user_groups' ,
                        component:ManageUserGroups,
                        meta:{title:'用户组管理'},
                    },
                    {
                        path:'roles' ,
                        component:ManageRoles,
                        meta:{title:'角色管理'},
                    }
                ]
            }
        ]
    },
    {
        path: '/login',
        name:'Login',
        component:Login,
        meta:{title:'矩元配送管理系统'}
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})  

router.beforeEach((to,from,next) =>{
    window.document.title = to.meta.title
    next()
})

export default router