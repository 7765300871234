<template>
  <div class="common-layout">
    <el-container class="loginform">
      <el-header class="form_header">
        <el-image :src="titleImg" class="imagestyle"></el-image>
      </el-header>
      <div class="form_right">
        <el-form ref="loginFormRef" :model="loginForm" label-width="auto" style="max-width: 350px" :rules="rules">
          <el-form-item label=" " prop="username">
            <el-input v-model="loginForm.username" placeholder="用户名" class="inputstyle" />
          </el-form-item>
          <el-form-item label=" " prop="password">
            <el-input v-model="loginForm.password" placeholder="密码" class="inputstyle" type="password" />
          </el-form-item>
          <br />
          <br />
          <el-form-item label=" ">
            <el-button type="primary" class="inputbutton"
              @click="onSubmit(loginFormRef)">马上登录</el-button>&nbsp;&nbsp;找回密码
          </el-form-item>
        </el-form>
      </div>
      <el-footer class="form_footer">
        © 成都距元科技有限公司 蜀ICP备2024099362号
        <br />
        Copyright 2023 - 2024 CDMatrix. All Rights Reserved
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { ElLoading } from 'element-plus';
import { useAuthStore } from '@/store/index';
import { useRouter } from 'vue-router';
import titleImg from '@/assets/systemtitle.png';
import api from '@/api/api'; // 导入完整的 api 对象
import { ElMessage, ElMessageBox } from 'element-plus'
const authStore = useAuthStore();
const router = useRouter();

const loginForm = reactive({
  appId: process.env.VUE_APP_APPID,
  username: '',
  password: '',
});

const rules = {
  username: [
    { required: true, message: '输入用户名', trigger: 'blur' },
  ],
  password: [
    { required: true, message: '输入密码', trigger: 'blur' },
  ],
};

const loginFormRef = ref(null);

const onSubmit = (formEL) => {
  formEL.validate((valid, fields) => {
    if (!valid) {
      console.log('表单验证失败', fields);
    } else {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      // 使用 api.userLogin 调用登录接口
      api.userLogin(loginForm).then((res) => {
        if (res.status === 200 && res.data.success) {
          authStore.login(res.data.data);

          // 调用 fetchUserPermissions 方法
          authStore.fetchUserPermissions().then(res => {
            loading.close();
            router.replace('/');
          }).catch((error) => {
            console.error('Failed to fetch user permissions:', error);
            loading.close();
          });
        } else {
          if (!res.data.success) {
            ElMessage({
              type: 'error',
              message: res.data.msg,
            })
          }
          console.log(res.data);
          loading.close();
        }
      }).catch((error) => {
        console.error('登录失败:', error);
        loading.close();
      });
    }
  });
};
</script>

<style scoped>
.common-layout {
  position: relative;
  width: 100%;
  height: 100vh;
  /* 父容器高度设置为视窗高度 */
  background-image: url('/public/images/loginbg.png');
}

.loginform {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 将元素的中心移动到父容器的中心 */
  width: 974px;
  height: 484px;
  background-image: url('/public/images/formbg.png');

  .form_right {
    margin-top: 40px;
    margin-left: 540px;
    width: 400px;
    height: 484px;
  }

  .inputstyle {
    background-image: url('/public/images/inputbg.png');
    width: 334px;
    height: 54px;
  }

  .inputbutton {
    width: 385px;
    height: 54px;
  }

  .imagestyle {
    border: none;
    width: 486px;
    height: 75px;
    margin-top: -60px;
    overflow: hidden;
  }
}

.form_header {
  text-align: center;
}

.form_footer {
  margin-top: 85px;
  color: #f8f8f8;
  line-height: 25px;
  text-align: center;
}
</style>