import { defineStore } from 'pinia';
import api from '@/api/api';

// 定义authStore
export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('token') || '',
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
    refreshToken: localStorage.getItem('refreshToken') || '',
    menuItems: [], // 新增 menuItems 属性
  }),
  actions: {
    // 模拟登录请求
    login(user) {
      this.token = user.accessToken;
      this.userInfo = user.user;
      this.refreshToken = user.refreshToken;

      // 持久化存储
      localStorage.setItem('token', this.token);
      localStorage.setItem('refreshToken', this.refreshToken);
      localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
    },
    // 登出操作
    logout() {
      this.token = '';
      this.userInfo = null;
      this.menuItems = [];

      // 清除持久化数据
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('refreshToken');
    },
    // 检查是否已经登录
    isLoggedIn() {
      return !!this.token;
    },
    // 设置菜单项
    setMenuItems(menuItems) {
      this.menuItems = menuItems;
    },
    // 获取用户权限列表
    async fetchUserPermissions() {
      try {
        const response = await api.fetchUserPermissions();
        // 可以在这里设置菜单项等
      } catch (error) {
        console.error('Failed to fetch user permissions:', error);
        throw error;
      }
    }
  },
  getters: {
    // 获取菜单项
    getMenuItems: (state) => state.menuItems
  }
});